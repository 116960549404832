// @flow

/**
 * Module dependencies.
 */

import { Box, Container } from 'components/core/layout';
import { Button } from 'components/core/buttons';
import { Type } from 'react-components/typography';
import { colors } from 'styles/colors';
import { ifProp, prop } from 'styled-tools';
import Metatags from 'components/metatags';
import React, { type Node } from 'react';
import color from 'color';
import styled from 'styled-components';

/**
 * Font components.
 */

const fontComponents = [{
  component: Type.H1,
  name: 'H1'
}, {
  component: Type.H2,
  name: 'H2'
}, {
  component: Type.H3,
  name: 'H3'
}, {
  component: Type.H4,
  name: 'H4'
}, {
  component: Type.H5,
  name: 'H5'
}, {
  component: Type.Paragraph,
  name: 'Paragraph'
}, {
  component: Type.Small,
  name: 'Small'
}];

/**
 * `Section` styled component.
 */

const Section = styled.section`
  margin-bottom: 3rem;
`;

/**
 * `Swatch` styled component.
 */

const Swatch = styled.div`
  align-items: center;
  background-color: ${prop('colorHex')};
  border-radius: 4px;
  color: ${ifProp('isDark', 'white', 'black')};
  display: grid;
  grid-auto-flow: row;
  height: 100px;
  justify-content: center;
  text-align: center;
  width: 100px;
`;

/**
 * `Styleguide` component.
 */

const Styleguide = (): Node => (
  <Container>
    <Metatags title={'Styleguide'} />

    <Type.H1
      marginBottom={'2rem'}
      marginTop={'2.5rem'}
    >
      {'Styleguide'}
    </Type.H1>

    <hr />

    <Section>
      <Type.H2 marginBottom={'1rem'}>
        {'Typography'}
      </Type.H2>

      {fontComponents.map(({ component: FontComponent, name }) => (
        <FontComponent key={name}>
          {`${name}: A single line of sample text`}
        </FontComponent>
      ))}

      <hr />

      <Type.H2
        marginBottom={'2rem'}
        marginTop={'2.5rem'}
      >
        {'Colors'}
      </Type.H2>

      <Box
        display={'flex'}
        flexWrap={'wrap'}
        gridGap={'1rem'}
      >
        {Object.entries(colors).map(([key, value]) => (
          <Swatch
            colorHex={value}
            isDark={color(value).isDark()}
            key={key}
          >
            <Box
              fontWeight={'600'}
              marginBottom={'1rem'}
            >
              {key}
            </Box>

            {value}
          </Swatch>
        ))}
      </Box>

      <hr />

      <Type.H2
        marginBottom={'2rem'}
        marginTop={'2.5rem'}
      >
        {'Buttons'}
      </Type.H2>

      <Box
        background={colors.blue800}
        display={'flex'}
        flexWrap={'wrap'}
        gridGap={'1rem'}
        padding={'2rem'}
      >
        {['fill', 'outline'].map(variant => ['primary', 'white'].map(colorTheme => (
          <Button
            colorTheme={colorTheme}
            key={colorTheme + variant}
            variant={variant}
          >
            {`${colorTheme}:${variant}`}
          </Button>
        )))}
      </Box>
    </Section>
  </Container>
);

/**
 * Export `Styleguide` component.
 */

export default Styleguide;
